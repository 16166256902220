import React from 'react';
import { Layout, SectionContact } from '@websites/components';
import { YellowBusinessContext } from '../contexts/YellowBusinessContext';

function ContactPage() {
  const { yellowBusiness, sections } = React.useContext(YellowBusinessContext);

  const hasTheme = !!yellowBusiness?.website?.theme;

  return (
    <>
      {hasTheme && (
        <Layout
          yellowBusiness={yellowBusiness}
          sections={sections}
          pageName={sections?.contactBusinessSection?.name}
        >
          <SectionContact section={sections?.contactBusinessSection} />
        </Layout>
      )}
    </>
  );
}

export default ContactPage;
